import React from "react";

function MobBoss(props) {
  return (
    <svg viewBox="43 0 6 11 " xmlns="http://www.w3.org/2000/svg">
      <g style={props.borderStyle}>
        <path
          d={
            "M 46.302083,10.985419 43.920833,5.958333 V 3.3125 l 1.5875,-1.322917 " +
            "V 0.93125003 h 1.5875 V 1.989583 l 1.5875,1.322917 -1.1e-5,2.645833 z"
          }
        />
      </g>
      <g style={props.iconStyle}>
        <path
          d={
            "m 46.023468,8.2571989 -0.315101,-0.098868 -0.07727,-0.1653528 -0.07727,-0.1653547 " +
            "h -0.200505 -0.200503 l -0.08157,-0.4006824 -0.08156,-0.4006827 0.05897,-0.056203 " +
            "c 0.06835,-0.06516 0.05053,-0.09227 0.176467,0.2681855 0.04377,0.1252429 0.08346,0.231909 " +
            "0.0882,0.2370331 0.0048,0.00511 0.128926,-0.046643 0.275982,-0.1150376 l 0.267358,-0.1243561 " +
            "0.109277,0.081552 c 0.06011,0.044852 0.119611,0.081552 0.132251,0.081552 0.0127,0 " +
            "0.06322,-0.031162 0.112424,-0.069248 l 0.08947,-0.069247 0.08947,0.069247 c 0.0492,0.038086 " +
            "0.09946,0.069248 0.111673,0.069248 0.01216,0 0.07659,-0.04653 0.143068,-0.1034019 0.09138,-0.07818 " +
            "0.133646,-0.098421 0.17332,-0.08298 0.02886,0.011228 0.150256,0.063242 0.269808,0.1155793 " +
            "l 0.217348,0.095158 0.08627,-0.2782074 0.08627,-0.2782074 0.06918,0.06083 0.06919,0.06083 " +
            "-0.04962,0.2654785 c -0.02728,0.1460131 -0.06222,0.32877 -0.07765,0.4061265 l -0.02803,0.1406475 " +
            "H 47.25424 47.048117 l -0.06043,0.163978 c -0.06648,0.180387 -0.05219,0.1702135 -0.4413,0.3143067 " +
            "l -0.20778,0.076946 z M 45.352887,6.9280108 45.28935,6.6981504 44.990624,6.4727376 " +
            "44.691901,6.2473249 V 6.1192105 c 0,-0.2725638 0.08612,-0.7767025 0.170638,-0.9988075 " +
            "0.0118,-0.030977 -0.0206,-0.056925 -0.126781,-0.10158 C 44.657328,4.985836 44.537495,4.923031 " +
            "44.469473,4.879259 44.18408,4.6956056 44.170608,4.3281616 44.429835,3.7983385 c 0.105236,-0.215096 " +
            "0.235067,-0.4121449 0.195886,-0.2973165 -0.01001,0.029468 -0.03681,0.1470501 -0.05943,0.261292 " +
            "-0.09244,0.4664288 0.09378,0.7138976 0.545883,0.7254877 0.15215,0.0039 0.185432,-0.00459 " +
            "0.267531,-0.068339 0.229997,-0.1785619 0.527712,-0.2622791 0.930603,-0.2616867 0.404696,5.977e-4 " +
            "0.554228,0.045491 1.004371,0.301551 0.06495,0.036945 0.115535,0.043637 0.228609,0.030251 " +
            "0.429209,-0.050805 0.584187,-0.2783944 0.495238,-0.7272656 -0.02264,-0.114242 -0.04938,-0.2318227 " +
            "-0.05945,-0.261292 -0.03917,-0.1148284 0.09065,0.082221 0.195887,0.2973167 0.18547,0.3790651 " +
            "0.230867,0.6731761 0.136471,0.8840991 -0.05632,0.1258473 -0.139189,0.1924921 -0.383639,0.3085698 " +
            "-0.09531,0.045257 -0.173284,0.084582 -0.173284,0.087388 0,0.00281 0.02401,0.074427 " +
            "0.05337,0.1591595 0.07695,0.2221592 0.127728,0.4886481 0.147413,0.773811 l 0.01716,0.2490387 " +
            "-0.294447,0.2124514 c -0.316112,0.2280873 -0.307579,0.2157981 -0.387303,0.5577645 -0.0095,0.040519 " +
            "-0.02719,0.073672 -0.03944,0.073672 -0.01216,0 -0.118806,-0.043074 -0.236813,-0.095721 l " +
            "-0.214553,-0.095721 -0.121077,0.095721 c -0.0666,0.052647 -0.130106,0.095721 -0.141119,0.095721 " +
            "-0.01108,0 -0.05535,-0.030139 -0.09848,-0.066975 -0.04314,-0.036836 -0.08643,-0.066975 " +
            "-0.09619,-0.066975 -0.0098,0 -0.05669,0.030139 -0.104288,0.066975 -0.11044,0.065886 " +
            "-0.01323,-5.977e-4 -0.06504,-0.036767 -0.11532,-0.08037 -0.05028,-0.043605 -0.0476,0.036836 " +
            "-0.0973,0.066485 -0.10697,-0.079281 -0.125993,-0.079281 -0.01902,0 -0.129105,0.048222 " +
            "-0.244627,0.1071601 -0.115517,0.058938 -0.213618,0.10716 -0.217989,0.10716 -0.0043,0 " +
            "-0.03655,-0.1034376 -0.07148,-0.2298598 z m 0.85433,-0.3013758 c 0.01448,-0.023881 " +
            "0.249104,-0.018056 0.273593,0.00679 0.008,0.00822 0.03011,0.00204 0.04888,-0.013767 " +
            "0.02728,-0.02298 0.0177,-0.06252 -0.04781,-0.1973448 -0.06653,-0.136893 -0.09385,-0.1686069 " +
            "-0.145196,-0.1686069 -0.0508,0 -0.07894,0.031542 -0.142944,0.1601841 -0.07502,0.1507993 " +
            "-0.08425,0.2349706 -0.02575,0.2349706 0.01412,0 0.03175,-0.00999 0.03921,-0.022224 z m " +
            "-0.223036,-0.4622356 0.08664,-0.1093967 -0.04641,-0.1044389 c -0.0502,-0.11296 -0.06832,-0.1223823 " +
            "-0.05419,0.054982 0.06102,0.2227549 c 0.03356,0.1225146 0.0713,0.2344346 0.08385,0.2487099 " +
            "0.01252,0.014267 -0.536626,-0.279015 l -0.220589,-0.07378 -0.05419,0.054982 0.138456,0.039824 " +
            "0.279754,0.056777 0.141299,0.016949 0.269781,0.032399 0.285502,0.034323 0.01573,0.00193 " +
            "0.06757,-0.045727 0.115231,-0.1058978 z m 1.113285,0.068048 c 0.125082,-0.00989 0.239333,-0.025712 " +
            "0.253887,-0.035188 0.01448,-0.00948 0.05612,-0.1221552 0.0924,-0.2504022 l 0.06594,-0.2331748 " +
            "-0.05727,-0.054587 -0.05727,-0.054587 -0.341764,0.1156973 c -0.328927,0.1113527 " +
            "-0.343983,0.1197344 -0.400778,0.2231269 l -0.05902,0.1074283 0.09483,0.1124182 c 0.06045,0.071654 " +
            "0.110564,0.1078502 0.138223,0.099824 0.02387,-0.00692 0.145732,-0.020675 0.270813,-0.030554 z"
          }
        />
        <path
          d={
            "m 45.019522,2.7833333 0.32064,0.4559227 0.320642,-0.4559227 0.320645,0.4559227 " +
            "0.320634,-0.4559227 0.320638,0.4559227 0.320646,-0.4559227 0.320638,0.4559227 " +
            "0.320639,-0.4559227 -0.198833,1.0991306 h -2.166824 z"
          }
        />
      </g>
    </svg>
  );
}

export default MobBoss;
