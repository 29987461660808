export const TYPE_OPTIONS = [
  { value: "stone", label: "Stone" },
  { value: "stone-motherlode", label: "Stone Motherlode" },
  { value: "ore", label: "Ore" },
  { value: "ore-motherlode", label: "Ore Motherlode" },
  { value: "wood", label: "Wood" },
  { value: "animal", label: "Animal" },
  { value: "animal-boss", label: "Animal Boss" },
  { value: "mob", label: "Mob" },
  { value: "mob-boss", label: "Mob Boss" },
  { value: "well", label: "Well" },
  { value: "grave", label: "Grave" },
  { value: "tactical-house", label: "Tactical House" },
  { value: "tactical-fire", label: "Tactical Fire" },
  { value: "tactical-fish", label: "Tactical Fish" },
];

export const RESOURCE_OPTIONS = [
  { value: "yew", type: "wood", label: "Yew" },
  { value: "birch", type: "wood", label: "Birch" },
  { value: "ash", type: "wood", label: "Ash" },
  { value: "oak", type: "wood", label: "Oak" },
  { value: "spruce", type: "wood", label: "Spruce" },
  { value: "granite", type: "stone", label: "Granite" },
  { value: "limestone", type: "stone", label: "Limestone" },
  { value: "travertine", type: "stone", label: "Travertine" },
  { value: "slate", type: "stone", label: "Slate" },
  { value: "marble", type: "stone", label: "Marble" },
  { value: "copper", type: "ore", label: "Copper" },
  { value: "tin", type: "ore", label: "Tin" },
  { value: "iron", type: "ore", label: "Iron" },
  { value: "silver", type: "ore", label: "Silver" },
  { value: "aurelium", type: "ore", label: "Aurelium" },
  { value: "spider", type: "animal", label: "Spider" },
  { value: "pig", type: "animal", label: "Pig" },
  { value: "cat", type: "animal", label: "Cat" },
  { value: "auroch", type: "animal", label: "Auroch" },
  { value: "elk", type: "animal", label: "Elk" },
  { value: "wolf", type: "animal", label: "Wolf" },
  { value: "bear", type: "animal", label: "Bear" },
  { value: "gryphon", type: "animal", label: "Gryphon" },
  { value: "human", type: "grave", label: "Human" },
  { value: "elven", type: "grave", label: "Elven" },
  { value: "monster", type: "grave", label: "Monster" },
  { value: "stoneborn", type: "grave", label: "Stoneborn" },
  { value: "guinecian", type: "grave", label: "Guinecian" },
  { value: "urgu", type: "mob", label: "Urgu" },
  { value: "elementals", type: "mob", label: "Elementals" },
  { value: "satyr", type: "mob", label: "Satyr" },
  { value: "aracoix", type: "mob", label: "Aracoix" },
  { value: "underhill", type: "mob", label: "Underhill" },
  { value: "enbarri", type: "mob", label: "Enbarri" },
  { vaule: "thralls", type: "mob", label: "Thralls" },
];
