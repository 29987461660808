import React from "react";

function Animal(props) {
  return (
    <svg viewBox="27 0 6 11 " xmlns="http://www.w3.org/2000/svg">
      <g style={props.borderStyle}>
        <path
          d={
            "M 30.427083,10.98542 28.045833,5.9583333 V 0.93125 " +
            "l 1.058334,1.0583333 H 31.75 l 1.058333,-1.0583333 -1.1e-5,5.0270833 z"
          }
        />
      </g>
      <g style={props.iconStyle}>
        <path
          d={
            "M 28.839583,5.5879168 V 5.1645837 l 0.220487,0.2116666 h 0.220486 0.440972 0.220486 " +
            "L 30.1625,5.1645837 v 0.4233331 l 0.220486,0.8466666 -0.440972,0.4233332 -0.220486,0.4233332 " +
            "H 29.280556 L 29.06007,6.8579166 28.619098,6.4345834 Z"
          }
        />
        <path
          d={
            "M 29.777485,3.259667 V 2.8363339 l 0.220487,0.2116666 h 0.220486 0.440972 0.220486 " +
            "L 31.100402,2.8363339 V 3.259667 L 31.320888,4.1063336 30.879916,4.5296668 30.65943,4.953 " +
            "H 30.218458 L 29.997972,4.5296668 29.557,4.1063336 Z"
          }
        />
        <path
          d={
            "M 30.691667,5.5879164 V 5.1645833 l 0.220487,0.2116666 h 0.220486 0.440972 0.220486 " +
            "L 32.014584,5.1645833 V 5.5879164 L 32.23507,6.434583 31.794098,6.8579162 31.573612,7.2812494 " +
            "H 31.13264 L 30.912154,6.8579162 30.471182,6.434583 Z"
          }
        />
      </g>
      <g style={props.cutoutStyle}>
        <path d="M 28.839583,6.2229168 29.104167,6.4875 h 0.264583 z"></path>
        <path d="M 30.1625,6.2229168 29.897917,6.4875 h -0.264584 z"></path>
        <path d="m 29.777485,3.894667 0.264584,0.2645832 h 0.264583 z"></path>
        <path d="M 31.100402,3.894667 30.835819,4.1592502 H 30.571235 Z"></path>
        <path d="m 30.691667,6.2229164 0.264584,0.2645832 h 0.264583 z"></path>
        <path d="M 32.014584,6.2229164 31.750001,6.4874996 H 31.485417 Z"></path>
      </g>
    </svg>
  );
}

export default Animal;
