import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  map: {
    width: "90%",
    height: "75vh",
  },
}));

export default useStyles;
